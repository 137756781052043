import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import useLanguageSwitcher from './UseLanguageSwitcher';

function TopBar({ setChosenLanguage, currentLang, currentCountry, setCurrentCountry, language }) {
  const [selectedCountry, setSelectedCountry] = useState('SE');
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [isCountryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const { changeLanguageAndRedirect } = useLanguageSwitcher();
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  // Initialize navigate hook from react-router-dom
  const navigate = useNavigate(); 

  useEffect(() => {
    // Function to check screen width
    const checkScreenSize = () => {
      setIsScreenSmall(window.innerWidth < 844);
    };

    // Check on component mount
    checkScreenSize();

    // Add event listener to check on window resize
    window.addEventListener('resize', checkScreenSize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const toggleCountryDropdown = () => setCountryDropdownOpen(!isCountryDropdownOpen);
  const toggleLanguageDropdown = () => setLanguageDropdownOpen(!isLanguageDropdownOpen);

  // Update selectCountry function to navigate to home screen after changing the country
  const selectCountry = (country) => {
    if(country !== 'SE'){
      changeLanguageAndRedirect('en');
      setSelectedLanguage('English');
    }
    else{
      changeLanguageAndRedirect('sv')
      setSelectedLanguage('Svenska')
    }
    
    setCurrentCountry(country);
    setCountryDropdownOpen(false);

    // Navigate to home screen after the country is changed
    navigate('/');
  };

  const selectLanguage = (language, languagecode) => {
    setSelectedLanguage(language);
    changeLanguageAndRedirect(languagecode);
    setLanguageDropdownOpen(false); 
  };

  const handleCloseClick = () => {
    setChosenLanguage(true);
  };

  useEffect(() => {
    switch (currentLang) {
      case 'en':
        setSelectedLanguage('English');
        break;
      case 'sv':
        setSelectedLanguage('Svenska');
        break;
      // Add more cases if you support more languages
      default:
        setSelectedLanguage('English'); // Default to English if no match
    }
  }, [currentLang]);

  return (
    <>
      {!isScreenSmall ? (
        <div style={{ backgroundColor: '#15AC9C', color: 'white', padding: '20px 20px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', height: '0px' }}>
          {/* Centered Text */}
          <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', textAlign: 'center' }}>
            <span>{language('topbar.title')}</span>
          </div>
  
          {/* Country Selector */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', position: 'absolute', right: '20px' }}>
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={toggleCountryDropdown}>
                {currentCountry &&
                  <img src={`https://flagcdn.com/${currentCountry.toLowerCase()}.svg`} alt="flag" style={{ width: '20px', marginRight: '5px' }} />
                }
                <span>{currentCountry}</span>
                <span style={{ marginLeft: '5px' }}>{isCountryDropdownOpen ? '▲' : '▼'}</span>
              </div>
              {isCountryDropdownOpen && (
                <ul style={{ position: 'absolute', top: '30px', backgroundColor: 'white', color: 'black', listStyleType: 'none', padding: '10px', margin: 0, borderRadius: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', zIndex: 3 }}>
                  <li onClick={() => selectCountry('SE')} style={{ cursor: 'pointer', padding: '5px 10px' }}>SE</li>
                  <li onClick={() => selectCountry('US')} style={{ cursor: 'pointer', padding: '5px 10px' }}>US</li>
                  <li onClick={() => selectCountry('DE')} style={{ cursor: 'pointer', padding: '5px 10px' }}>DE</li>
                  <li onClick={() => selectCountry('ROW')} style={{ cursor: 'pointer', padding: '5px 10px' }}>ROW</li>
                </ul>
              )}
            </div>
  
            {/* Language Selector */}
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={toggleLanguageDropdown}>
                <span>{selectedLanguage}</span>
                {currentCountry === 'SE' && (
                  <span style={{ marginLeft: '5px' }}>{isLanguageDropdownOpen ? '▲' : '▼'}</span>
                )}
              </div>
              {isLanguageDropdownOpen && currentCountry === 'SE' && (
                <ul style={{ position: 'absolute', top: '30px', backgroundColor: 'white', color: 'black', listStyleType: 'none', padding: '10px', margin: 0, borderRadius: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', zIndex: 3 }}>
                  <li onClick={() => selectLanguage('English', 'EN')} style={{ cursor: 'pointer', padding: '5px 10px' }}>English</li>
                  <li onClick={() => selectLanguage('Svenska', 'SV')} style={{ cursor: 'pointer', padding: '5px 10px' }}>Svenska</li>
                </ul>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='langContainer'>
        <div className='langInnerContainer'>
          <div className='langInfo'>Are you at the right place?</div>
            <div className='countrypickerContainer'>
                <div className='countrypicker'>
                    <div className='langDropdown'>
                    <select
                        className='langDropdownSelect'
                        value={currentCountry}
                        onChange={(e) => selectCountry(e.target.value)}
                    >
                        <option value="US">US</option>
                        <option value="SE">Sweden</option>
                        {/* Add more languages here if needed */}
                    </select>
                    </div>
                </div>
                <div className='countrypicker'>
                    <div className='langDropdown'>
                    <select
                        className='langDropdownSelect'
                        value={selectedLanguage}
                        onChange={(e) => selectLanguage(e.target.value)}
                    >
                        <option value="en">English</option>
                        <option value="sv">Svenska</option>
                        {/* Add more languages here if needed */}
                    </select>
                    </div>
                </div>
            </div>
            
          <div className='langConfirm' onClick={() => console.log('test')}>
            <div>confirm</div>
          </div>
        </div>
        <img className='langClose' src='close.png' onClick={handleCloseClick} />
      </div>
      )}
    </>
  );
}

export default TopBar;
