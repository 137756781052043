import React, { useState } from 'react';

function Dropdown({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown" style={{ paddingTop: '40px' }}>
      <div
        onClick={toggleDropdown}
        className="dropdownHeader"
        style={{
          cursor: 'pointer',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative', // Needed for the line animation
        }}
      >
        <h4 style={{ margin: 0, color: '#373737' }}>{title}</h4>
        <span style={{ fontSize: '40px', alignItems:'center' }}>{isOpen ? '-' : '+'}</span>
      </div>

      <div
        className="dropdownContent"
        style={{
          maxHeight: isOpen ? '200px' : '0',
          overflow: 'hidden',
          transition: 'max-height 0.3s ease',
          paddingTop: isOpen ? '10px' : '0',
        }}
      >
        {children}
      </div>

      {/* This is the animated line */}
      <div
        style={{
          marginTop: isOpen ? '10px' : '0',
          width: '100%',
          height: '1px',
          backgroundColor: '#ccc',
          transition: 'margin-top 0.3s ease',
        }}
      />
    </div>
  );
}

export default Dropdown;
