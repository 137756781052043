import { createCheckout, getProductDetail } from "../../../backend/shopify/shopifyService";
import { useState } from "react";
import { getProducts } from "../../../backend/shopify/shopifyService";
import { useEffect } from "react";
import { FindProductId, findProductId } from "../../../backend/shopify/services/Product";
import Dropdown from "../../../components/products/Dropdown";
import './ProductMenu.css'

function ProductMenu({products, setChosenProduct, chosenProduct, language}) {
  const [cart, setCart] = useState([]);
  const [product, setProduct] = useState([]);

  const [variantImages, setVariantImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const selectedVariant = products.variants.edges.find(
    (variant) => variant.node.title === chosenProduct
  );

  console.log(products)
  // Set variantId to the ID of the selected variant
  const variantId = selectedVariant ? selectedVariant.node.id : null;

  const handleAddToCart = () => {
    console.log('cart', variantId)
    if (variantId) {
      setCart([...cart, { variantId, quantity: 1 }]);
      console.log(cart);
    }
  };


  const handleCheckout = async () => {
    const checkout = await createCheckout(cart.map(item => ({ variantId: item.variantId, quantity: item.quantity })));
    window.location.href = checkout.webUrl;
  };



  const clicker = (node) => {
    console.log(node)
  };


  if (error) return <p>Error loading product details: {error.message}</p>;

  if (!products) return <p>No product found</p>;


  return (
    <div className='productMenu' style={{ marginTop: "0px"}}>
      <div className='productMenuInner'>
          <div>
            <div>
              <h3 style={{margin: '0'}}>{products.title}</h3>
              <h4 style={{color:'#373737', marginTop: '10px'}}>{products.variants.edges[0].node.priceV2.amount}kr</h4>
            </div>

            <p id='descrtext' style={{ color:'#6D6D6D'}}>
                {products.description}
            </p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <h4 style={{color:'#373737', marginBottom:'10px'}}>Färg&nbsp;</h4>
            <h4 style={{color:'#373737', marginBottom:'10px'}}>- {language(`product.${chosenProduct}`)}</h4>
          </div>
          {products.variants.edges.length > 0 ? (
            <div style={{display:'flex', alignItems:'center'}}>
              {products.variants.edges.map((variantEdge, index) => (
                <div key={index}>
                  <div className={`productButton ${chosenProduct === variantEdge.node.title ? 'active' : 'inactive'}`} onClick={() => (setChosenProduct(variantEdge.node.title), console.log(variantEdge.node))} style={{backgroundColor:variantEdge.node.title}}></div>
                </div>
              ))}
            </div>
          ) : (
            <p>No variants available</p>
          )}
          <div style={{ marginTop: "50px", width: '100%' }}>
              <button onClick={handleAddToCart} style={{ height: '50px', width: "200px", backgroundColor:'#292929', color:'white', borderRadius:'10px' }}>Add to Cart</button>
              <button onClick={handleCheckout} style={{ height: '50px', width: "150px", marginLeft: '10px' }} disabled={cart.length === 0}>Checkout</button>
          </div>
          <Dropdown title="Produktdetaljer" >
            <p style={{color:'#6D6D6D'}}>{products.description}</p>
          </Dropdown>

          <Dropdown title="Leveransinformation">
            <p style={{color:'#6D6D6D'}}>Leverans inom 3-5 arbetsdagar. Skickas samma dag om det beställs innan 12.</p>
            <p style={{color:'#6D6D6D'}}>Gratis returer inom 30 dagar.</p>
          </Dropdown>
      </div>
    </div>
  );
}

export default ProductMenu;