import React, { useState, useEffect, memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import './ProductView.css';
import ProductCarousel from './components/ProductCarousel';
import ProductMenu from './components/ProductMenu';
import { useProductContext } from '../../components/products/ProductContext';
import ProductContainer from '../HomePage/components/ProductContainer';
import ProductShell from '../../components/products/ProductShell';
import ProductHandler from './components/ProductHandler';

const ProductView = ({language}) => {
  const { color } = useParams();  // Extract the color parameter from the URL
  const [chosenProduct, setChosenProduct] = useState('Blue');
  const { products, variantImages } = useProductContext();
  const [variants, setVariants] = useState();
  const [loading, setLoading] = useState(true);

  const memoizedVariants = useMemo(() => {
    console.log(products)
    if (products.length !== 0 && products.variants) {
      return products.variants.edges;
    }
    return null;
  }, [products]);

  useEffect(() => {
    if (memoizedVariants) {
      setVariants(memoizedVariants);
      setLoading(false);
    }
  }, [memoizedVariants]);

  useEffect(() => {
    if (color) {
      setChosenProduct(color);
    } else {
      setChosenProduct('Blue');  // Default value if no color is specified
    }
  }, [color]);

  if (loading) {
    return <div>Loading...</div>;  // Optional: Add a loading indicator
  } else {
    return (
      <ProductHandler
        color={chosenProduct}
        products={products}
        variantImages={variantImages}
        language={language}
      />
    );
  }
};

export default memo(ProductView);

