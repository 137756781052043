import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProductCarousel.css'; // Import the CSS

const ProductCarousel = ({ chosenProduct, variantImages, variants }) => {
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [mainImageLoaded, setMainImageLoaded] = useState(false);
  const [thumbnailsLoaded, setThumbnailsLoaded] = useState([]);

  // Find the chosen variant
  const chosenVariant = variants.length > 0
    ? variants.find(variant => variant.node.title === chosenProduct)
    : null;

  // Get the images for the chosen variant
  const images = chosenVariant ? variantImages[chosenVariant.node.id] : [];

  useEffect(() => {
    setMainImageIndex(0);
    setMainImageLoaded(false);
    setThumbnailsLoaded(new Array(images.length).fill(false));
  }, [chosenProduct, images]);

  // Function to check if an image is cached
  const checkImageCache = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  // Handle the loading status of thumbnail images
  const handleThumbnailLoad = (index) => {
    setThumbnailsLoaded(prevState => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  // Handle the loading status of the main image
  const handleMainImageLoad = () => {
    setMainImageLoaded(true);
  };

  // Check if images are cached
  useEffect(() => {
    const loadImages = async () => {
      // Check if main image is cached
      if (images.length > 0) {
        await checkImageCache(images[mainImageIndex].node.originalSrc);
        setMainImageLoaded(true);
      }

      // Check if thumbnail images are cached
      const thumbnailStatus = await Promise.all(
        images.map(image => checkImageCache(image.node.originalSrc))
      );
      setThumbnailsLoaded(thumbnailStatus);
    };

    loadImages();
  }, [images, mainImageIndex]);

  return (
    <div className='productCarousel'>
      <div className='thumbnailContainer'>
        {images.map((imageEdge, index) => (
          <div key={index} className='thumbnailWrapper'>
            {!thumbnailsLoaded[index] && <Skeleton className='thumbnailImage' />}
            <img
              src={imageEdge.node.originalSrc}
              alt={imageEdge.node.altText || `Thumbnail ${index + 1}`}
              className={`thumbnailImage ${mainImageIndex === index && hoveredImageIndex === null ? 'active' : ''}`}
              onClick={() => setMainImageIndex(index)}
              onMouseEnter={() => setHoveredImageIndex(index)}
              onLoad={() => handleThumbnailLoad(index)}
              style={{ display: thumbnailsLoaded[index] ? 'block' : 'none' }}
            />
          </div>
        ))}
      </div>
      <div className='mainImageSlide'>
        {!mainImageLoaded && <Skeleton className='carouselImage' />}
        <img
          src={images[
            hoveredImageIndex !== null && hoveredImageIndex < images.length
              ? hoveredImageIndex
              : mainImageIndex
          ]?.node.originalSrc}
          alt={images[
            hoveredImageIndex !== null && hoveredImageIndex < images.length
              ? hoveredImageIndex
              : mainImageIndex
          ]?.node.altText || 'Main Image'}
          className='carouselImage'
          onLoad={handleMainImageLoad}
          style={{ display: mainImageLoaded ? 'block' : 'none' }}
        />
        <div className='arrowsContainer'>
          <img
            src='/arrowLeftActive.png'
            style={{ height: '60px', marginRight: '10px' }}
            onClick={() => setMainImageIndex(prevIndex => Math.max(prevIndex - 1, 0))}
          />
          <img
            src='/arrowRightActive.png'
            style={{ height: '60px' }}
            onClick={() => setMainImageIndex(prevIndex => Math.min(prevIndex + 1, images.length - 1))}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;
